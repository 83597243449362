import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            years: [],
            year: '',
            files: [],
            checkedList: [],
            layerGroup: [],
        };
    },
    computed: {
        ...mapGetters([
            'heritageId',
        ]),
        hasFile: function () {
            return this.files.length > 0;
        },
        showCheckBox: function () {
            return this.files.length > 1;
        }
    },
    watch: {
        myMap: {
            immediate: true,
            handler: function () {
                if (this.myMap) {
                    this.layerGroup = window.L.layerGroup().addTo(this.myMap);
                    this.getYearList();
                }
            }
        },
        year: {
            immediate: true,
            handler: function (year) {
                if (!year) {
                    return;
                }
                this.getMapData()
            }
        },
        checkedList: {
            deep: true,
            handler: function (checkedList) {
                const index = checkedList.findIndex(ele => ele);
                if (index >= 0) {
                    this.initMapInfo(this.files[index]);
                }
            }
        }
    },
    created() { },
    mounted() { },
    methods: {
        ...mapActions([
            'getCtYearData',
            'getYcMapData',
            "getVectorStyle"
        ]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 获取年份列表
        async getYearList() {
            this.years = [];
            let itemId = this.itemId || this.monitoritemId;
            const res = await this.getCtYearData({
                itemId: itemId,
                glycbtid: this.heritageId
            });
            if (res && Array.isArray(res)) {
                this.years = res.map(item => item.NF);
                this.year = this.years[0];
            }
        },
        // 设置文件和地图
        setFilesAndMap(res) {
            this.checkedList = [];
            let files = [];
            if (res && Array.isArray(res) && res.length) {
                files = res;
                files.forEach((ele, index) => { this.checkedList[index] = false });
                this.checkedList = new Array(files.length);
                this.checkedList[0] = true;
            }
            this.files = files;
        },
        // 初始化地图
        initMapInfo(info) {
            if (!info.URL) {
                this.common.showMsg("该年份地图URL配置不正确,请在数据管理与接入中核查", "info")
                return;
            }
            // 设置中心点和展示级别  
            const latlng = (info.ZXD || window.mapCenter).split(",").map(ele => Number(ele));
            const zoom = info.ZSJB || 13;
            this.myMap.setView(latlng, zoom);
            this.drawMap(info, this.layerGroup);
        },
        // 绘制地图
        async drawMap(info, group) {
            this.clearLayersGroup(group)
            const url = info.URL;
            let layer = info.TCMC;
            if ((info.MapType == '0' || info.MapType == '1') && !layer) {
                this.common.showMsg("该影像图层不正确", "info")
                return;
            }

            switch (info.MapType) {
                case '0': // WMS 类型的地图 的展示，主要为天地图
                    let emap = new window.L.tileLayer(
                        `${url}?T=${layer}&x={x}&y={y}&l={z}&tk=${tk}`,
                        {
                            minZoom: 3,
                            maxZoom: 17,
                            crs: L.CRS.EPSG3857,
                            showName: "底图",
                            showType: "底图",
                        }
                    );
                    group.addLayer(emap);
                    break
                case '1':// WMTS 类型的地图 的展示
                    let ign = new window.L.tileLayer(`${url}`);
                    window.L.layerGroup([ign]).addTo(group);
                    break
                case '2':// 矢量切片 的展示
                    let layerStyle = await this.getVectorStyle({
                        url: url
                    });
                    window.L.mapboxGL({
                        accessToken: window.MAPBOX_TOKEN,
                        style: layerStyle,
                    }).addTo(group);
                    this.dealVectorLayers();
                    break
            }
        },
        dealVectorLayers() {
            let vectorLayers = document.querySelectorAll(".mapboxgl-map");
            vectorLayers.forEach(layer => {
                layer.style.position = "absolute";
                layer.style.left = 0;
                layer.style.top = 0;
            });
        },
        changeChecked(checked, index) {
            this.checkedList.fill(false);
            if (index === 0) {
                this.checkedList[0] = true;
            } else if (checked) {
                this.checkedList[index] = true;
            } else {
                this.checkedList = [true];
            }
        },
        // 获取地图数据
        async getMapData() {
            // 基础数据 5遗产总图 6遗产要素分布图 10 使用功能图 90101保护区划图
            // 监测数据 301遗产总图 302遗产要素分布图 303使用功能基准图 20 建设控制 保护区划图 20
            let num = ""
            switch (this.itemId) {
                case 301:
                    num = 5
                    break
                case 302:
                    num = 6
                    break
                case 303:
                    num = 10
                    break
                case 90101:
                    num = 20
                    break
            }
            const res = await this.getYcMapData({
                itemId: num,
                year: this.year
            });
            this.setFilesAndMap(res);
        }
    },
};